import React, { useState, useContext } from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import { UserContext } from "../helpers/context"

import YounionLinkList from "../components/YounionLinkList"
import LeafletMap from "../components/LeafletMap"
import ImagePopup from "../components/ImagePopup"
import SideMenue from "../components/SideMenu"
import NotLoggedIn from "../components/NotLoggedIn"
import ShowApp from "../components/ShowApp"
import MinWageApp from '../components/MinWageApp'
import Login from "../components/Login"

export default props => {
  const { data, pageContext } = props
  const { _id, parents } = pageContext
  const { younion } = data
  const { promotions, promotionCategories, minwages } = younion
  const user = useContext(UserContext)
  const promoCatAllowed = promotionCategories.filter(
    promo => !promo.membersOnly || user?.sessionId
  )
  const promoAllowed = promotions.filter(
    promo => !promo.membersOnly || user?.sessionId
  )
  const promotion = promoAllowed.find(promo => promo._id === _id)
  const {
    // categoriesIds,
    header,
    subheader,
    text,
    webcontent,
    membersignin,
    showApp,
    applicationIds,
    links,
    linksForMembers,
    downloads,
    downloadsForMembers,
    files,
    filesForMembers,
    images,
    locations,
  } = promotion || {}
  // const parentPromotion =
  //   categoriesIds?.length > 0 &&
  //   promoCatAllowed.find(category => category._id === categoriesIds[0])

  if (!promotion) return <NotLoggedIn />

  let buildingSlug = "/service"

  const [selectedImage, setSelectedImage] = useState(0)
  const [popupOpen, setPopupOpen] = useState(false)

  const moveImageLeft = () =>
    selectedImage < 1
      ? setSelectedImage(0)
      : setSelectedImage(selectedImage - 1)
  const moveImageRight = () =>
    selectedImage >= images.length - 1
      ? setSelectedImage(images.length - 1)
      : setSelectedImage(selectedImage + 1)

  return (
    <div>
      <h1>
        {parents.map((parent, index) => {
          const { _id, title, slug } = promotionCategories.find(
            cat => cat._id === parent
          )
          buildingSlug += `/${slug}`
          return (
            <span key={_id}>
              <Link to={buildingSlug}>{title}</Link>
              {parents.length - 1 !== index ? " > " : null}
            </span>
          )
        })}
      </h1>
      <div className="service">
        <div className="service-left">
          <SideMenue
            // selected={parentPromotion._id}
            categories={promoCatAllowed}
          />
        </div>
        <div className="service-right">
          <div className="detail detail-notDotted">
            <div className="detail-left">
              {images.length ? (
                <div className="detail-image-box">
                  {selectedImage > 0 ? (
                    <div
                      className="detail-arrow-left"
                      role="button"
                      tabIndex={0}
                      onClick={moveImageLeft}
                      onKeyPress={moveImageLeft}
                    >
                      <svg width="12.5" height="12.5">
                        <polygon
                          points="12.5,0 0,6.25 12.5,12.5"
                          style={{ fill: "white" }}
                        />
                      </svg>
                    </div>
                  ) : null}
                  {selectedImage < images.length - 1 ? (
                    <div
                      className="detail-arrow-right"
                      role="button"
                      tabIndex={0}
                      onClick={moveImageRight}
                      onKeyPress={moveImageRight}
                    >
                      <svg width="12.5" height="12.5">
                        <polygon
                          points="0,0 12.5,6.25 0,12.5"
                          style={{ fill: "white" }}
                        />
                      </svg>
                    </div>
                  ) : null}
                  <div
                    className="detail-image-popup"
                    role="button"
                    tabIndex={0}
                    onClick={() => setPopupOpen(true)}
                    onKeyPress={() => setPopupOpen(true)}
                  >
                    <Img
                      fluid={images[selectedImage].image.childImageSharp.fluid}
                      alt={images[selectedImage].title}
                      objectFit="none"
                      className="detail-image"
                    />
                  </div>
                </div>
              ) : null}
              <ImagePopup
                open={popupOpen}
                setOpen={setPopupOpen}
                images={images}
                defaultId={selectedImage}
              />
              {membersignin ? (
                <div className="younionlink">
                  <Link to="/signin">Hier registrieren</Link>
                </div>
              ) : null}
              <YounionLinkList
                className="younionlink"
                links={links}
                linksForMembers={linksForMembers}
                title="Links"
              />
              <YounionLinkList
                className="younionlink"
                links={downloads}
                linksForMembers={downloadsForMembers}
                title="Downloads"
                download={true}
              />
              <YounionLinkList
                className="younionlink"
                links={files}
                linksForMembers={filesForMembers}
                title="Dateien"
                files={true}
                download={true}
              />
              {!user?.sessionId &&
                (linksForMembers?.length || downloadsForMembers?.length) ? (
                  <div>
                    <h4>Loggen Sie sich ein für mehr Informationen</h4>
                    <Login />
                  </div>
                ) : null}
            </div>
            <div className="detail-right">
              <h1>{header}</h1>
              <h4>{subheader}</h4>
              <div dangerouslySetInnerHTML={{ __html: showApp ? text : webcontent }} />
              <LeafletMap locations={locations} />

              {showApp && applicationIds && applicationIds[0] === "mindestlohn_pruefung" && <ShowApp />}
              {showApp && applicationIds && applicationIds[0] === "mindestlohn_gemeinden" && <MinWageApp minwages={minwages}/>}

              {membersignin ? (
                <div className="mobile-younionlink">
                  <Link to="/signin">Hier registrieren</Link>
                </div>
              ) : null}
              <YounionLinkList
                className="mobile-younionlink"
                links={links}
                linksForMembers={linksForMembers}
                title="Links"
              />
              <YounionLinkList
                className="mobile-younionlink"
                links={downloads}
                linksForMembers={downloadsForMembers}
                title="Downloads"
                download={true}
              />
              <YounionLinkList
                className="younionlink"
                links={files}
                linksForMembers={filesForMembers}
                title="Dateien"
                files={true}
                download={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  query {
    younion {
      promotions {
        ...promotionFields
      }

      promotionCategories {
        ...promotionCategoryFields
        children {
          ...promotionCategoryCards
          children {
            ...promotionCategoryCards
          }
        }
        parent {
          ...promotionCategoryCards
        }
        promotions {
          ...promotionCards
        }
      }

      minwages {
        ...minwageFields
      }
    }
  }
`
