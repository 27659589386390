import React, { useState } from "react"
import Img from "gatsby-image"

import "./imagePopup.css"

export default ({ images, open, setOpen, defaultId = 0 }) => {
  if (!images || !open) return null

  const [selected, setSelected] = useState(defaultId)

  const moveLeft = () =>
    selected < 1 ? setSelected(0) : setSelected(selected - 1)

  const moveRight = () =>
    selected >= images.length - 1
      ? setSelected(images.length - 1)
      : setSelected(selected + 1)

  const closePopup = () => setOpen(false)

  return (
    <div className="lightstable-effekt">
      {selected > 0 ? (
        <div
          className="lightstable-arrow-left"
          role="button"
          tabIndex={0}
          onClick={moveLeft}
          onKeyPress={moveLeft}
        >
          <svg width="50" height="50">
            <polygon points="50,0 0,25 50,50" style={{ fill: "white" }} />
          </svg>
        </div>
      ) : null}
      {selected < images.length - 1 ? (
        <div
          className="lightstable-arrow-right"
          role="button"
          tabIndex={0}
          onClick={moveRight}
          onKeyPress={moveRight}
        >
          <svg width="50" height="50">
            <polygon points="0,0 50,25 0,50" style={{ fill: "white" }} />
          </svg>
        </div>
      ) : null}
      <div
        className="lightstable-inside-box"
        role="button"
        tabIndex={0}
        onClick={closePopup}
        onKeyPress={closePopup}
      >
        <Img
          fluid={images[selected].image.childImageSharp.fluid}
          className="lightstable-effekt-image"
        />
      </div>
    </div>
  )
}
