import React, { useState, useEffect } from "react"
// import { Map, Marker, Popup, TileLayer } from "react-leaflet"
// import L from "leaflet"
import iconRetinaUrl from "leaflet/dist/images/marker-icon-2x.png"
import iconUrl from "leaflet/dist/images/marker-icon.png"
import shadowUrl from "leaflet/dist/images/marker-shadow.png"

import "leaflet/dist/leaflet.css"
import "./leafletMap.css"

export default ({ locations }) => {
  if (!locations || (locations && locations.length === 0)) return null
  if (typeof window === undefined) return null

  const [reactLeaflet, setReactLeaflet] = useState()
  const [leaflet, setLeaflet] = useState()

  useEffect(() => {
    setReactLeaflet(require("react-leaflet"))
    setLeaflet(require("leaflet"))
  }, [])

  if (!reactLeaflet || !leaflet) return null

  const { Map, Marker, Popup, TileLayer } = reactLeaflet
  const L = leaflet

  delete L.Icon.Default.prototype._getIconUrl

  let DefaultIcon = L.icon({
    iconRetinaUrl,
    iconUrl,
    shadowUrl,
    iconSize: [24, 36],
    iconAnchor: [12, 36],
    popupAnchor: [0, -36],
  })
  L.Marker.prototype.options.icon = DefaultIcon

  const getLatLngBounds = () => {
    const latlngs = []
    locations.map(({ address }) => latlngs.push([address.lat, address.lng]))
    return latlngs
  }

  return (
    <Map bounds={getLatLngBounds()} maxZoom={19}>
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png"
      />
      {locations.map(({ _id, address, title }) => (
        <Marker key={_id} position={[address.lat, address.lng]}>
          {title ? <Popup>{title}</Popup> : null}
        </Marker>
      ))}
    </Map>
  )
}
