import React, { useContext } from "react"

import { UserContext } from "../helpers/context"

const ListElement = (key, value, download, sessionId) => (
  <li key={key}>
    <a
      href={
        download && sessionId
          ? `https://db.younion.qnipp.com/younion/members/download/${sessionId}?file=${encodeURIComponent(
              key
            )}`
          : key
      }
      target="_blank"
      rel="noopener noreferrer"
      download={download}
    >
      {value}
    </a>
  </li>
)

const renderList = (title, list, files, download, sessionId) => (
  <>
    <h4>{title}</h4>
    <ul>
      {files
        ? list.map(({ name, link }) => ListElement(link, name, download, false))
        : list.map(({ key, value }) =>
            ListElement(key, value, download, sessionId)
          )}
    </ul>
  </>
)

export default ({
  links,
  linksForMembers,
  title,
  files = false,
  download = false,
  className = "",
}) => {
  const user = useContext(UserContext)

  return links?.length || linksForMembers?.length ? (
    <div className={className}>
      {links?.length ? renderList(title, links, files, download) : null}
      {user?.sessionId && linksForMembers?.length
        ? renderList(
            `${title} für Mitglieder`,
            linksForMembers,
            files,
            download,
            user.sessionId
          )
        : null}
    </div>
  ) : null
}
