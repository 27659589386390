import React, { useState } from "react"

import "./MinWageApp.css"

const getDatetoString = date => {
  const d = new Date(date)
  return `am ${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`
}

const ArrowDown = () => (
  <svg width="10" height="10">
    <polygon points="0,0 5,10 10,0" style={{ fill: "rgb(171, 15, 31)" }} />
  </svg>
)
const ArrowUp = () => (
  <svg width="10" height="10">
    <polygon points="0,10 10,10 5,0" style={{ fill: "rgb(171, 15, 31)" }} />
  </svg>
)

export default ({ minwages }) => {
  const districts = [
    "Eisenstadt und Umgebung",
    "Neusiedl",
    "Mattersburg",
    "Oberpullendorf",
    "Oberwart",
    "Güssing",
    "Jennersdorf",
  ]
  const colour = {
    angenommen: "lightgreen",
    abgelehnt: "lightred",
    unbekannt: "lightgrey",
  }
  const [activeDropdown, setActiveDropDown] = useState([])

  const handleOnClick = district => () => {
    setActiveDropDown(
      activeDropdown.find(active => active === district)
        ? activeDropdown.filter(dist => dist !== district)
        : [...activeDropdown, district]
    )
  }

  return (
    <div className="minwage">
      <ul>
        {districts.map(dist => (
          <li>
            <a className="district" onClick={handleOnClick(dist)}>
              {dist}{" "}
              {activeDropdown.find(active => active === dist) ? (
                <ArrowUp />
              ) : (
                <ArrowDown />
              )}
            </a>

            {activeDropdown.find(active => active === dist) && (
              <ul>
                {minwages
                  .filter(minwage => minwage.district[0] === dist)
                  .map(local => (
                    <li
                      style={{
                        backgroundColor: colour[local.requestKey],
                      }}
                    >
                      <div style={{ marginLeft: "6px" }}>
                        {local.local_community}
                      </div>
                      <span
                        style={{
                          marginLeft: "14px",
                        }}
                      >
                        {local.request[0]}{" "}
                        {local.requestDate &&
                          getDatetoString(local.requestDate)}
                      </span>
                    </li>
                  ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}
