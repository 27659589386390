import React, { useState, useContext } from "react"

import { UserContext } from "../helpers/context"

import "./ShowApp.css"

const checkMail = email => {
  const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
  return reg.test(email)
}

const WedgeForm = () => {
  const user = useContext(UserContext)
  const [submitText, setSubmitText] = useState({ success: {}, error: {} })

  const validate = e => {
    const { email, phone, contract, payslip } = e.target
    const error = {}

    if (!email.value && !phone.value) {
      error.requiredContact = "Email oder Telefon wurde nicht ausgefüllt."
    }

    if (email.value && !checkMail(email.value)) {
      error.email = "Email ungültig."
    }

    if (!contract.value) {
      error.contract = "Es ist keine Datei vorhanden"
    }

    if (!payslip.value) {
      error.payslip = "Es ist keine Datei vorhanden"
    }

    setSubmitText({ success: {}, error })

    if (!Object.keys(error).length) {
      return true
    }

    return false
  }

  const onSubmit = e => {
    e.preventDefault()

    if (validate(e)) {
      const fd = new FormData(e.target)
      fd.append("name", user.username)

      fetch(e.target.action, {
        method: "POST",
        redirect: "manual",
        body: fd,
      }).then(response => {
        if (response.type === "opaqueredirect") {
          // success
          setSubmitText({
            success: {
              text: "Ihre Anfrage wurde zugeschickt! Wir melden uns in Kürze.",
            },
            error: {},
          })
        } else {
          // failed
          setSubmitText({
            success: {},
            error: {
              failed:
                "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
            },
          })
        }
      })
    }
  }

  return (
    <form
      action="https://form.younion.qnipp.com/process.php"
      method="post"
      onSubmit={onSubmit}
    >
      <input type="hidden" name="form_tools_form_id" value="7" />
      <input
        type="hidden"
        name="form_tools_redirect_url"
        value="http://localhost:8000"
      />

      <div>
        <button className="wage-button" type="submit">Absenden</button>
        <div className="wage-form-text-input">
          <label>
            <span>Deine Email:</span>
            <input type="text" id="email" name="email" />
          </label>
          <div className="submit-message message-error">{submitText.error.email}</div>
        </div>
        <div className="wage-form-text-input">
          <label>
            <span>Oder Telefon:</span>
            <input type="text" id="phone" name="phone" />
          </label>
        </div>
        <div className="submit-message message-error">{submitText.error.requiredContact}</div>
        <div className="wage-form-text-input" style={{ marginTop: "24px" }}>
          <label>
            <span>Vertrag:</span>
            <input type="file" id="contract" name="contract" />
          </label>
          <div className="submit-message message-error">{submitText.error.contract}</div>
          <label>
            <span>Lohnzettel:</span>
            <input type="file" id="payslip" name="payslip" />
          </label>
          <div className="submit-message message-error">{submitText.error.payslip}</div>
        </div>
        <div className="submit-message message-success">{submitText.success.text}</div>
        <div className="submit-message message-error">{submitText.error.failed}</div>
      </div>
    </form>
  )
}

export default () => (
  <div style={{ marginTop: "32px" }}>

    <WedgeForm />

    <h3>Es geht auch über unsere App!</h3>

    <p>Lad dir die App jetzt runter!</p>

    <div>
      <a href="https://play.google.com/store/apps/details?id=com.qnipp.mobile.younion">
        <h3>Android</h3>
      </a>
      <a href="https://apps.apple.com/at/app/younion-burgenland/id1482676595">
        <h3>IOS</h3>
      </a>
    </div>
  </div>
)
